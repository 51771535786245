import './App.scss';

import About from "./components/Sections/About";
import Hero from "./components/Sections/Hero";
import Nav from "./components/Nav/Nav";
import Partners from "./components/Sections/Partners";
import Services from "./components/Sections/Services";
import Story from "./components/Sections/Story";

function App() {
  return (
    <div className="main">
      <Nav />
      <div className="content">
        <Hero/>
        <div className="info">
          <About/>
          <Services/>
        </div>
        <Story/>
        <Partners/>
      </div>
      <footer>
        <div className="footer-content">
          <div className="footer-logo">
            <img src="/favicon.ico" alt="Logo" width="50" height="50" />
            <p>© 2024 QOL MedTech. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
