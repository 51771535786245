import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ConstructionIcon from '@mui/icons-material/Construction';
import { ReactElement } from "react";
import styles from './Services.module.scss'
export default function Services() {
  return (
    <div className={styles.services}>
      <h1 id="services" >Services</h1>
      <div className={styles.service_list}>
        {services.map((service, index) => (
          <Service key={index} title={service.title} description={service.description} icon={service.icon} />
        ))}
      </div>
   </div>
  )
}

function Service({ title, description, icon }: { title: string, description: string, icon: ReactElement }) {
  return (
    <div className={styles.service}>
      <div className={styles.icon}>
        {icon}
      </div>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
}


const services = [
   {
      title: 'Shorten Recovery Time',
      description: 'Accelerate healing with precise, data-driven guidance.',
      icon: <AccessTimeIcon />,
    },
    {
      title: 'Enhance Exercise Compliance',
      description: 'Ensure patients follow their exercise regimens correctly with real-time cues and reminders.',
      icon: <AutoFixHighIcon />,
    },
    {
      title: 'Optimize Movement and Performance',
      description: 'Get detailed insights into your movements to improve form, prevent injury, and maximize results.',
      icon: <ConstructionIcon />,
    },
    {
      title: 'Cost-Effective Solutions',
      description: 'Reduce healthcare costs by minimizing the need for in-person visits and leveraging technology that supports ongoing care and monitoring.',
      icon: <AttachMoneyIcon />,
    }
]