import styles from './Nav.module.scss';

export default function Nav() {
   return (
      <div className={styles.nav}>
         <h1 className={styles.title}>QOL MedTech</h1>
         <div className={styles.links}>
            <NavScrollButton text="About" id="about" />
            <NavScrollButton text="Services" id="services" />
            <NavScrollButton text="Story" id="story" />
            <NavScrollButton text="Partners" id="partners" />
            <NavScrollButton text="Contact" id="contact" />
            <NavScrollButton text="Career" id="career" />
         </div>
      </div>
   );     
}

export function NavScrollButton({text, id}: {text: string, id: string}) {
   function handleClick() {
      const element = document.getElementById(id);
      if (element) {
         element.scrollIntoView({ behavior: 'smooth' });
      }
   }

   return (
      <div className={styles.button} onClick={handleClick}>
         <p className={styles.button_text}>{text}</p>
      </div>
   );
}