import { Button } from "@mui/joy"
import LaunchIcon from '@mui/icons-material/Launch';
import styles from "./Partners.module.scss"

export default function Partners() {
   const jobsButton = () => {
      window.open("https://www.linkedin.com/company/qolmedtech/jobs/", "_blank")
   }
   

  return (
   <div className={styles.partners}>
      <div className={styles.partners_content}>
         <h1 id="partners">Partners</h1>
         <p>
            We’re proud to collaborate with leading organizations like Alberta Innovates and IRAP to
            drive innovation in healthcare technology.
         </p>
         <div className={styles.partners_logos}>
            <img src="https://albertainnovates.ca/wp-content/uploads/2020/05/ABINNOVATES-Alternate.png" alt="Alberta Innovates" />
            <img src="./nrc.webp" alt="National Research Council" style={{height: "150px"}} />
         </div>
      </div>
      <div className={styles.join_team}>
         <h1 id="career">Join Our Team</h1>
         <p>
            At QOL MedTech, we’re more than just a dynamic startup—we’re innovators in healthcare
            technology. Our culture thrives on creativity, enthusiasm, and a relentless drive to learn
            and improve. We’re building a team of passionate individuals committed to making a real
            difference in healthcare access. Our core values are genuineness, intellectual honesty,
            commitment to completion, and meticulous attention to detail. We empower every team
            member to bring their unique skills and perspectives to the table.
         </p>
         <Button 
            className={styles.button}
            variant="outlined" 
            color="primary" 
            size="md" 
            sx={{fontSize: "1rem", backgroundColor: "white", color: "#61dafb", borderRadius: "20px", borderColor: "white", padding: "10px 20px"}}
            onClick={jobsButton}>
            <LaunchIcon sx={{marginRight: "10px", fontSize: "1.25rem"}} />
            <p>View Jobs</p>
         </Button>
      </div>
   </div>
   )
}
