import styles from './Story.module.scss';

export default function Story() {
  return (
    <div className={styles.story}>
      <h1 id="story">Our Story</h1>
      <div className={styles.story_content}>
         <div className={styles.story_image}>
            <img src="/story.jpg" alt="Our Story" />
         </div>
         <div className={styles.story_info}>
            <h2>Pachari and Peter - FOUNDERS OF QOL MEDTECH</h2>
            <p>
            "Peter and I noticed something troubling—people struggling with joint pain and mobility
            issues, whether they were athletes, older adults, or just individuals trying to stay active,
            had few practical solutions. My father’s knee pain made every step difficult, and Peter’s
            foot injury threw off his posture, leading to even more problems. We knew there had to be a
            better way.
            What if you could have a wearable solution that didn’t just track your movement but
            actually guided you to recover faster, move better, and prevent injuries? What if it was
            designed for everyone—from athletes to older adults, and even for those in remote areas
            who can’t easily access healthcare?
            That’s why we created QOL MedTech. We spent countless hours understanding the needs
            of people just like you—physiotherapists, caregivers, athletes, and those looking to
            maintain their health. Our wearable technology not only monitors your movements but
            provides real-time feedback and guidance tailored to your specific needs.
            Ready to improve your health, optimize your performance, or support your patients more
            effectively? QOL MedTech is here to empower you on that journey."
            </p>
         </div>
         
      </div>
      <div className={styles.work_together}>
            <h2 id="contact"> Work with us </h2>
            <p>At QOL MedTech, we’re dedicated to transforming healthcare for everyone—whether you’re
               in a bustling urban center or a remote rural community. Our wearable technology supports
               healthcare providers, caregivers, physiotherapists, athletes, and individuals by
               accelerating recovery, enhancing exercise compliance, and optimizing performance.
               We’re here to partner with you to make healthcare more accessible, effective, and
               impactful, no matter where you are. Let’s work together to bring advanced, personalized
               care to your organization, practice, or community. Connect with us today, and let’s start
               making a difference together.
            </p>
            <p>
               info@qolmedtech.com
            </p>
         </div>
    </div>
  )
}
