import Button from '@mui/joy/Button';
import styles from './Hero.module.scss';

export default function Hero() {
   return (
      <div className={styles.hero}>
         <div className={styles.hero_text}>
            <h1 className={styles.hero_title}>Revolutionizing Recovery, Compliance, and Performance with Wearable Technology</h1>
            <h2 className={styles.hero_subtitle}>At QOL MedTech, we design wearable technology that accelerates recovery, enhances exercise compliance, and optimizes performance—no matter where you are. </h2>
         </div>
         <Button className={styles.button} variant="solid" size="lg" 
            onClick={() => {
               const aboutSection = document.getElementById('about');
               if (aboutSection) {
                  aboutSection.scrollIntoView({ behavior: 'smooth' });
               }
            }}
         >Learn More</Button>
      </div>
   );
}

